export const useMenuStore = defineStore("MenuStore", {
  state: () => ({
    careDays: new Map()
  }),
  actions: {
    // TODO: add option to force update
    getMenuForWeek (careDayId, week, year, forceUpdate = false) {
      const getMenuForWeek = useNuxtApp().$getMenuForWeek;
      getMenuForWeek(careDayId, week, year).then((menuItem) => {
        let menuData = this.careDays.get(careDayId);

        if (!menuData) {
          menuData = new Map();
          this.careDays.set(careDayId, menuData);
        }

        const weekSearchIndex = getWeekSearchIndex(week, year);
        const alreadyExists = menuData.has(weekSearchIndex);

        if (!forceUpdate && alreadyExists) {
          // console.log(`Menu for ${week}/${year} already exists`);
          return;
        }

        if (menuItem) {
          // console.log(`Menu for ${week}/${year} does not exist`);
          menuData.set(weekSearchIndex, menuItem);
        } else {
          // console.log(`Menu for ${week}/${year} is empty`);
          menuData.set(weekSearchIndex, null);
        }
      });
    },
    reset () {
      this.careDays = new Map();
    }
  }
});

const getWeekSearchIndex = (week, year) => `${week}-${year}`;
